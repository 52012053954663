import React from "react";
import TextTyper from "../TextTyper/TextTyper";
import "./Logo.scss";

/**
 * @returns A section with a SVG logo animated in css and a textTyper
 */
export default function Logo() {
    return (
        <section className="accueil">
            <svg
                className="logo"
                width="240"
                height="184"
                viewBox="0 0 63.499999 48.683333"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className="logo__chevron"
                    d="m 34.45268,1.100484e-4 c -0.22659,0 1.27336,1.5648499516 15.9737,16.6630699516 4.40981,4.52914 8.01812,8.3063 8.01812,8.3938 0,0.0875 -5.10221,5.39578 -11.33833,11.79618 -6.23611,6.4004 -11.27329,11.72353 -11.19363,11.82925 0.11542,0.15317 18.0125,-15.16933 25.87025,-22.14852 L 63.5,25.00892 49.08899,12.50426 C 41.16292,5.62689 34.57648,1.100484e-4 34.45268,1.100484e-4 Z"
                />
                <path
                    className="logo__SigmaB"
                    d="m 32.62385,10.23669 v 4.82607 4.82606 l 6.10867,-0.12816 6.10815,-0.12764 0.0832,-1.14153 c 0.0458,-0.62777 -0.0649,-1.32371 -0.24649,-1.54668 -0.18156,-0.22297 -2.57911,-0.40889 -5.32784,-0.41289 l -4.99763,-0.007 v -1.19579 -1.19528 h 5.44049 5.44102 l 0.60565,1.17357 c 0.43486,0.84257 0.60289,1.68611 0.59789,2.98897 -0.0157,3.64377 -0.83453,4.44469 -4.54184,4.44469 h -2.85409 v 1.6738 1.6738 h 2.94246 c 2.80572,0 2.9781,0.0573 3.7021,1.23765 0.98553,1.60675 1.05518,4.25866 0.16433,6.2668 l -0.59532,1.34204 -5.45134,0.14521 -5.45135,0.14572 v -4.56871 -4.56871 h 0.74001 0.74052 v 3.10833 3.10783 h 4.30155 c 3.57897,0 4.36631,-0.12591 4.6886,-0.75035 0.29239,-0.5665 0.32477,-0.97694 0.13229,-1.67379 -0.23389,-0.84677 -0.55056,-0.92295 -3.82509,-0.92295 h -3.56981 v -3.10833 -3.10834 h -2.46806 -2.46807 v 7.1732 7.17218 h 7.01198 7.01197 l 0.63872,-1.47123 c 0.56081,-1.29146 0.63924,-1.90149 0.63924,-4.99401 0,-2.99589 -0.0855,-3.71612 -0.57258,-4.81315 l -0.57309,-1.29036 0.57309,-1.19734 c 0.49632,-1.03752 0.57258,-1.69018 0.57258,-4.88859 0,-3.01914 -0.0937,-3.92206 -0.51521,-4.96042 -1.12394,-2.76848 -1.51263,-2.91041 -8.43153,-3.0794 z"
                />
                <path
                    className="logo__SigmaB"
                    d="m 28.671635,9.95092 c -0.946208,1.3e-4 -2.182826,0.0114 -3.798217,0.0289 l -6.862631,0.0749 -0.04702,1.73685 c -0.05974,2.20553 0.15232,3.26905 0.652673,3.26905 0.559962,0 0.75861,-0.53923 0.75861,-2.05982 v -1.37305 h 4.976441 c 2.737054,0 4.97644,0.0727 4.97644,0.16175 0,0.089 -1.191599,2.43454 -2.648416,5.21209 -1.650677,3.14711 -2.646801,5.26869 -2.644801,5.63066 0.0023,0.33077 1.142367,2.9515 2.648418,6.08697 1.45461,3.02836 2.644799,5.57108 2.644799,5.64978 0,0.0792 -2.672877,0.14366 -5.939689,0.14366 h -5.939694 v -2.98328 c 0,-3.06456 -0.154513,-3.88193 -0.733806,-3.88193 -0.189529,0 -0.229443,0.8197 -0.229443,4.71961 v 4.72012 h 7.432622 c 7.714469,0 7.818129,-0.0157 7.818129,-1.17615 0,-0.26919 -1.28225,-3.17391 -2.849441,-6.45491 -1.567199,-3.28098 -2.937288,-6.16443 -3.044775,-6.40787 -0.164938,-0.37378 0.277551,-1.35103 2.846856,-6.28903 1.67309,-3.21551 3.04377,-6.07299 3.04478,-6.35 0.002,-0.3557 -0.22321,-0.45873 -3.061835,-0.45837 z"
                />
                <path
                    className="logo__chevron"
                    d="M 27.587981,0.00114005 C 27.472556,-0.15203995 9.574958,15.17046 1.717208,22.14966 L 0,23.67463 14.411007,36.1793 c 7.926092,6.87737 14.512511,12.50414 14.636315,12.50414 0.226602,0 -1.273365,-1.56483 -15.973701,-16.66306 -4.40981,-4.52914 -8.018116,-8.3063 -8.018116,-8.3938 0,-0.0875 5.102721,-5.39579 11.338844,-11.79618 C 22.630459,5.42999 27.667642,0.10686005 27.587981,0.00114005 Z"
                />
            </svg>
            <p className="logo__desc">
                <TextTyper
                    text={"Web developper"}
                    ready={true}
                    delay={4000}
                    speed={200}
                    cursor={"\u2588"}
                    pageHIndex={0}
                    pageVIndex={0}
                />
            </p>
        </section>
    );
}
